* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  font-size: 20px;
  --button-color: #f19066;
  --button-color-hover: #f27c49;
  --button-color-active: #f2d0c2;
  --button-color-disabled: #ebb59e;
  --gray: #535353;
  --lightgray: #757575;
}

.startpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.startpage-container h1 {
  font-family: "Caveat", cursive;
  font-size: 3rem;
  color: var(--gray);
  display: inline;
}

.startpage-container a {
  text-decoration: none;
  background-color: var(--button-color);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
}

.startpage-container a:hover {
  background-color: var(--button-color-hover);
}

.startpage-container a:active {
  background-color: var(--button-color-active);
}

.motivate-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.motivate-container a {
  display: flex;
  align-items: center;
  height: 20vh;
  text-decoration: none;
}

.motivate-container h1 {
  font-family: "Caveat", cursive;
  color: var(--gray);
  font-size: 3rem;
}

.motivate-container h1:hover {
  color: var(--lightgray);
}

.motivate-container h2 {
  color: var(--gray);
}

.poster-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 60vh;
  width: 100%;
  max-width: 1000px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 32px -5px #000000;
  box-shadow: 0px 0px 32px -5px #000000;
  background-size: cover;
  padding: 1rem;
  background-position: center;
}

.poster-container p {
  color: #ddd;
  font-size: 2rem;
  text-shadow: 0px 0px 5px black;
}

.poster-container .quote-content {
  font-family: "Caveat", cursive;
  font-size: 2rem;
  line-height: 1.5;
}

.poster-container .quote-author {
  font-family: "Homemade Apple";
  font-size: 1.5rem;
}

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  gap: 1rem;
}

.button-container button {
  padding: 0.5rem 1rem;
  border: none;
  text-decoration: none;
  background-color: var(--button-color);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 1rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.button-container button:hover:enabled {
  background-color: var(--button-color-hover);
}

.button-container button:active:enabled {
  transition: none;
  background-color: var(--button-color-active);
}

.button-container button:disabled {
  transition: none;
  background-color: var(--button-color-disabled);
  cursor: default;
}

.flex-end {
  position: absolute;
  bottom: 4rem;
}

@media screen and (max-width: 1000px) {
  .poster-container {
    border-radius: 0;
  }
}

@media screen and (max-width: 640px) {
  :root {
    font-size: 16px;
  }

  .poster-container {
    -webkit-box-shadow: 0px 0px 16px -5px #000000;
    box-shadow: 0px 0px 16px -5px #000000;
  }
}
